export default {
    data() {
        return {
            blDialogAdicionar: false,
            blLoading: false,
            imagens: [],
            obForm: {
                imagem:null,
                prioridade: null,
            }
        }
    },

    created() {
        this.$root.$api.get("home/carrousels").then(response => {
            this.imagens = response.retorno;
            this.blLoading = false;
        });
    },

    methods: {

        salvarImagemCarrousel() {
            this.blLoading = true;
            this.$root.$api
            .file("home/salvarCarrousel", this.obForm)
            .then(response => {
                this.blLoading = false;
                this.blDialogAdicionar = false;
                this.imagens.push(response.retorno);
                this. obForm =  {
                    imagem:null,
                    prioridade: null,
                };
                this.$router.go()
            });
        },
        deletar(id) {
            this.$root.$api
            .delete("home/excluirCarrousel/" + id)
            .then(response => {
                this.blDialogExcluir = false;

                this.$router.go()
            });
        }
        
    },
}